import './App.css'
import facebook from './images/facebook.jpg'
import twitter from './images/twitter.jpg'
import logoforcogsa from './images/forcogsa-logotipo.png'
import logoforcogsa40Aniv from './images/40_aniversario_logo.png'
import banner from './images/cupon.jpg'

const showmodal = true;

function TopBar() {
  return (
    <>
      <div className='uk-expand fc-topbar-border uk-visible@l uk-background-default'>
        {/* TOP HEADER - PHONE */}
        <div className='uk-container'>
          <div>
            <div className='uk-flex uk-flex-center'>
              <div>
                <ul className="uk-iconnav">
                  <li>
                    <span className='uk-text-small fc-lightgray-text uk-text-middle'>REALIZAMOS TRABAJOS A TODA LA REP&Uacute;BLICA MEXICANA.
                      <span className='fc-green-text'> SOLICITA UNA COTIZACI&Oacute;N AL</span>
                      <span className='fc-blue-text uk-text-bold uk-text-default'> ✆ (33) 3777-1616</span>
                    </span>
                  </li>
                  <li><a href="https://www.facebook.com/forcogsa" target='_blank' rel="noreferrer"><img src={facebook} alt="facebook_fc" /></a></li>
                  <li><a href="https://twitter.com/Forcogsa" target='_blank' rel="noreferrer"><img src={twitter} alt="twitter_fc" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='uk-container-expand uk-background-default'>
        <nav className="uk-navbar-container uk-navbar-transparent uk-margin-remove uk-padding-small" data-uk-navbar>
          <div className="uk-navbar-center">
            <a className="uk-navbar-item uk-logo" href="#"><img src={logoforcogsa40Aniv} alt="logoforcogsa" className='uk-height-small'/></a>
          </div>
        </nav>
      </div>
      <div id="modal-home" className="uk-flex-top" data-uk-modal>
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
          <button className="uk-modal-close-default" type="button" data-uk-close></button>
          <img src={banner} alt='banner'/>
        </div>
      </div>
    </>
  );
}

export default TopBar;