import './App.css'

function Menu() {
    return (
        <div className='uk-container-expand menu-dark-gray'>
            <div className='uk-container'>
                <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                    <div className="uk-navbar-center">
                        <ul className="uk-navbar-nav">
                            <li><a href="#inicio" className='fc-navbar' data-uk-scroll>Inicio</a></li>
                            <li><a href="#nosotros" className='fc-navbar' data-uk-scroll>Nosotros</a></li>
                            <li><a href="#servicios" className='fc-navbar' data-uk-scroll>Servicios</a></li>
                            <li><a href="#productos" className='fc-navbar' data-uk-scroll>Productos</a></li>
                            <li><a href="#portafolio" className='fc-navbar' data-uk-scroll>Portafolio</a></li>
                            <li><a href="#contacto" className='fc-navbar' data-uk-scroll>Contacto</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className='fc-gradient'></div>
        </div>
    );
}

export default Menu;
