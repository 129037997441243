import '../App.css'

/* BLOCKS */
import blocks_1 from '../images/portafolio/block-01-forcogsa.jpg'
import blocks_2 from '../images/portafolio/block-02-forcogsa.jpg'
/* ENCUADERNADO */
import encuadernado_1 from '../images/portafolio/cuaderno-01-forcogsa.jpg'
import encuadernado_2 from '../images/portafolio/cuaderno-02-forcogsa.jpg'
/* FORMA CONTINUA */
import formacontinua_1 from '../images/portafolio/formas-continuas-01-forcogsa.jpg'
import formacontinua_2 from '../images/portafolio/formas-continuas-02-forcogsa.jpg'
import formacontinua_3 from '../images/portafolio/formas-continuas-03-forcogsa.jpg'
import formacontinua_4 from '../images/portafolio/formas-continuas-04-forcogsa.jpg'
/* PAPEL DE SEGURIDAD */
import papelseguridad_1 from '../images/portafolio/boleto02_forcogsa.jpg'
import papelseguridad_2 from '../images/portafolio/boleto04_forcogsa.jpg'
import papelseguridad_3 from '../images/portafolio/boleto07_forcogsa.jpg'
import papelseguridad_4 from '../images/portafolio/boleto13_forcogsa.jpg'
import papelseguridad_5 from '../images/portafolio/ticket02_forcogsa.jpg'
import papelseguridad_6 from '../images/portafolio/vales03_forcogsa.jpg'
import papelseguridad_7 from '../images/portafolio/seguridad-01-forcogsa.jpg'
import papelseguridad_8 from '../images/portafolio/seguridad-02-forcogsa.jpg'
import papelseguridad_9 from '../images/portafolio/seguridad-03-forcogsa.jpg'
import papelseguridad_10 from '../images/portafolio/seguridad-04-forcogsa.jpg'
import papelseguridad_11 from '../images/portafolio/seguridad-05-forcogsa.jpg'
import papelseguridad_12 from '../images/portafolio/vales-gasolina-04-forcogsa.jpg'
/* PRENSA PLANA */
import prensaplana_1 from '../images/portafolio/broshure01_forcogsa.jpg'
import prensaplana_2 from '../images/portafolio/broshure02_forcogsa.jpg'
import prensaplana_3 from '../images/portafolio/carpeta01_forcogsa.jpg'
import prensaplana_4 from '../images/portafolio/carpeta03_forcogsa.jpg'
import prensaplana_5 from '../images/portafolio/manual_forcogsa.jpg'
import prensaplana_6 from '../images/portafolio/menu01_forcogsa.jpg'
import prensaplana_7 from '../images/portafolio/menu02_forcogsa.jpg'
import prensaplana_8 from '../images/portafolio/prensa-01-forcogsa.jpg'
import prensaplana_9 from '../images/portafolio/prensa-02-forcogsa.jpg'
import prensaplana_10 from '../images/portafolio/prensa-03-forcogsa.jpg'
import prensaplana_11 from '../images/portafolio/prensa-04-forcogsa.jpg'
import prensaplana_12 from '../images/portafolio/prensa-05-forcogsa.jpg'
/* ROLLOS */
import rollo_1 from '../images/portafolio/rollos-01-forcogsa.jpg'
import rollo_2 from '../images/portafolio/rollos-02-forcogsa.jpg'
import rollo_3 from '../images/portafolio/rollos-03-forcogsa.jpg'
/* TICKETS */
import tickets_1 from '../images/portafolio/boleto01_forcogsa.jpg'
import tickets_2 from '../images/portafolio/boleto03_forcogsa.jpg'
import tickets_3 from '../images/portafolio/boleto05_forcogsa.jpg'
import tickets_4 from '../images/portafolio/boleto06_forcogsa.jpg'
import tickets_5 from '../images/portafolio/boleto08_forcogsa.jpg'
import tickets_6 from '../images/portafolio/boleto09_forcogsa.jpg'
import tickets_7 from '../images/portafolio/boleto10_forcogsa.jpg'
import tickets_8 from '../images/portafolio/boleto11_forcogsa.jpg'
import tickets_9 from '../images/portafolio/boleto12_forcogsa.jpg'
import tickets_10 from '../images/portafolio/pase01_forcogsa.jpg'
import tickets_11 from '../images/portafolio/pase02_forcogsa.jpg'
import tickets_12 from '../images/portafolio/pase03_forcogsa.jpg'
import tickets_13 from '../images/portafolio/ticket01_forcogsa.jpg'
import tickets_14 from '../images/portafolio/vales01_forcogsa.jpg'
import tickets_15 from '../images/portafolio/vales-gasolina-01-forcogsa.jpg'
import tickets_16 from '../images/portafolio/vales-gasolina-02-forcogsa.jpg'
import tickets_17 from '../images/portafolio/vales-gasolina-03-forcogsa.jpg'
import tickets_18 from '../images/portafolio/vales-gasolina-04-forcogsa.jpg'
import tickets_19 from '../images/portafolio/vales-gasolina-05-forcogsa.jpg'
/* ITEMS LIST */
const blocks_list = [
    { image: blocks_1, meta: 'blocks' }, { image: blocks_2, meta: 'blocks' },
]
const encuadernado_list = [
    { image: encuadernado_1, meta: 'encuadernado' }, { image: encuadernado_2, meta: 'encuadernado' },
]
const formacontinua_list = [
    { image: formacontinua_1, meta: 'formacontinua' }, { image: formacontinua_2, meta: 'formacontinua' },
    { image: formacontinua_3, meta: 'formacontinua' }, { image: formacontinua_4, meta: 'formacontinua' }
]
const papelseguridad_list = [
    { image: papelseguridad_1, meta: 'papelseguridad' }, { image: papelseguridad_2, meta: 'papelseguridad' },
    { image: papelseguridad_3, meta: 'papelseguridad' }, { image: papelseguridad_4, meta: 'papelseguridad' },
    { image: papelseguridad_5, meta: 'papelseguridad' }, { image: papelseguridad_6, meta: 'papelseguridad' },
    { image: papelseguridad_7, meta: 'papelseguridad' }, { image: papelseguridad_8, meta: 'papelseguridad' },
    { image: papelseguridad_9, meta: 'papelseguridad' }, { image: papelseguridad_10, meta: 'papelseguridad' },
    { image: papelseguridad_11, meta: 'papelseguridad' }, { image: papelseguridad_12, meta: 'papelseguridad' }
]
const prensaplana_list = [
    { image: prensaplana_1, meta: 'prensaplana' }, { image: prensaplana_2, meta: 'prensaplana' },
    { image: prensaplana_3, meta: 'prensaplana' }, { image: prensaplana_4, meta: 'prensaplana' },
    { image: prensaplana_5, meta: 'prensaplana' }, { image: prensaplana_6, meta: 'prensaplana' },
    { image: prensaplana_7, meta: 'prensaplana' }, { image: prensaplana_8, meta: 'prensaplana' },
    { image: prensaplana_9, meta: 'prensaplana' }, { image: prensaplana_10, meta: 'prensaplana' },
    { image: prensaplana_11, meta: 'prensaplana' }, { image: prensaplana_12, meta: 'prensaplana' }
]
const rollos_list = [
    { image: rollo_1, meta: 'rollos' }, { image: rollo_2, meta: 'rollos' }, { image: rollo_3, meta: 'rollos' }
]
const tickets_list = [
    { image: tickets_1, meta: 'tickets' }, { image: tickets_2, meta: 'tickets' },
    { image: tickets_3, meta: 'tickets' }, { image: tickets_4, meta: 'tickets' },
    { image: tickets_5, meta: 'tickets' }, { image: tickets_6, meta: 'tickets' },
    { image: tickets_7, meta: 'tickets' }, { image: tickets_8, meta: 'tickets' },
    { image: tickets_9, meta: 'tickets' }, { image: tickets_10, meta: 'tickets' },
    { image: tickets_11, meta: 'tickets' }, { image: tickets_12, meta: 'tickets' },
    { image: tickets_13, meta: 'tickets' }, { image: tickets_14, meta: 'tickets' },
    { image: tickets_15, meta: 'tickets' }, { image: tickets_16, meta: 'tickets' },
    { image: tickets_17, meta: 'tickets' }, { image: tickets_18, meta: 'tickets' },
    { image: tickets_19, meta: 'tickets' }
]

function Portafolio() {
    return (
        <div className='uk-background-default uk-container-expand' id='portafolio'>
            <div className='uk-container'>
                <div className="uk-flex uk-flex-center uk-margin-remove">
                    <div className="uk-card uk-card-body uk-text-center uk-margin-remove">
                        <p className='uk-width-1-1 uk-align-center fc-green-text uk-h3 uk-text-uppercase uk-margin-remove'>CONOCE NUESTROS</p>
                        <p className='uk-width-1-1 uk-align-center fc-gray-text uk-h1 uk-text-bold uk-text-uppercase uk-margin-remove'>PROYECTOS</p>
                    </div>
                </div>
            </div>
            <div className='uk-container uk-text-center uk-height-auto'>
                <div uk-filter="target: .js-filter">
                    <div className="uk-grid-small uk-grid-divider uk-child-width-auto" data-uk-grid>
                        <div>
                            <ul className="uk-subnav uk-subnav-pill fc-subnav-proyectos" data-uk-margin uk-tab>
                                <li uk-filter-control="[data-all='all']"><a href="#">Todo</a></li>
                                <li uk-filter-control="[data-section='blocks']"><a href="#">Blocks</a></li>
                                <li uk-filter-control="[data-section='encuadernado']"><a href="#">Encuadernado</a></li>
                                <li uk-filter-control="[data-section='formacontinua']"><a href="#">Forma Continua</a></li>
                                <li uk-filter-control="[data-section='papelseguridad']"><a href="#">Papel de Seguridad</a></li>
                                <li uk-filter-control="[data-section='prensaplana']"><a href="#">Prensa Plana</a></li>
                                <li uk-filter-control="[data-section='rollos']"><a href="#">Rollo Punto de Venta</a></li>
                                <li uk-filter-control="[data-section='tickets']"><a href="#">Tickets</a></li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <ul className="js-filter uk-child-width-1-2 uk-child-width-1-5@m uk-text-center uk-margin-top" data-uk-grid="masonry: true">
                            <ProyectosList list={blocks_list} />
                            <ProyectosList list={encuadernado_list} />
                            <ProyectosList list={formacontinua_list} />
                            <ProyectosList list={papelseguridad_list} />
                            <ProyectosList list={prensaplana_list} />
                            <ProyectosList list={rollos_list} />
                            <ProyectosList list={tickets_list} />
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal-images" className="uk-flex-top" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <img src="" alt="" id="modalPicture"/>
                </div>
            </div>
        </div>
    );
}


function ProyectosList(props) {
    const items = props.list.map((item, i) =>
        <li key={i} data-section={item.meta} data-all='all' className='hoverProduct' uk-toggle='target: #modal-images' onClick={() => imageClick(item.image)}>
            <div className="uk-card uk-padding-small uk-card-body">
                <img src={item.image} alt=""/>
            </div>
        </li>
    )
    return items
}

const imageClick = (image) => {
    let element = document.getElementById("modalPicture");
    element.src = image;
}

export default Portafolio;