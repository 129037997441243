import './App.css';
import TopBar from './TopBar'
import Menu from './Menu'
import Navigation from './Navigation'
/* Sections */
import Nosotros from './components/Nosotros'
import Servicios from './components/Servicios'
import Productos from './components/Productos'
import Portafolio from './components/Portafolio'
import Contacto from './components/Contacto'

import Footer from './Footer'

function App() {
  return (
    <div className='uk-expand'>
      <div className='fc-bg-video uk-visible@s'>
        <video src="https://tinyurl.com/2gbulva7" width="1920" height="1080" loop muted playsInline uk-video="autoplay: inview"></video>
      </div>
      { /* TOPBAR SECTION */}
      <TopBar />
      <Menu />
      <Navigation/>
      {/* NOSOTROS */}
      <Nosotros/>
      <Servicios/>
      <Productos/>
      <Portafolio/>
      <Contacto/>
      <Footer/>
      <script>UIkit.modal("#modal-home").show();</script>
    </div>
    
  );
}

export default App;
