import '../App.css'
import disenio from '../images/iconos_diseno.png'
import instalaciones from '../images/iconos_instalaciones.png'
import asesoria from '../images/iconos_asesoria.png'
import filosofia from '../images/iconos_filosofia.png'
import marcas from '../images/marcas.png'
import mision_icon from '../images/mision-forcogsa.png'
import mision from '../images/mision-forcogsa.jpg'
import vision_icon from '../images/vision-forcogsa.png'
import vision from '../images/vision-forcogsa.jpg'
import valores_icon from '../images/valores-forcogsa.png'
import valores from '../images/valores-forcogsa.jpg'

/* SLIDE NAVIGATION IMAGES */
import slide_1 from '../images/instalaciones/02-forcogsa.jpg'
import slide_2 from '../images/instalaciones/05-forcogsa.jpg'
import slide_3 from '../images/instalaciones/07-forcogsa.jpg'
import slide_4 from '../images/instalaciones/08-forcogsa.jpg'
import slide_5 from '../images/instalaciones/09-forcogsa.jpg'
import slide_6 from '../images/instalaciones/12-forcogsa.jpg'
import slide_7 from '../images/instalaciones/13-forcogsa.jpg'
import slide_8 from '../images/instalaciones/14-forcogsa.jpg'
import slide_9 from '../images/instalaciones/15-forcogsa.jpg'
import slide_10 from '../images/instalaciones/16-forcogsa.jpg'
import slide_11 from '../images/40_aniversario.png'


function Servicios() {
    return (
        <>
            {/* VIDEO FORCOGSA MODAL */}
            <div className='uk-container-expand uk-background-default uk-margin-remove' id='servicios'>
                <div className="uk-flex uk-flex-center uk-margin-remove">
                    <div className="uk-card uk-card-body uk-text-center uk-margin-remove">
                        <p className='uk-width-1-1 uk-align-center fc-green-text uk-h3 uk-text-uppercase uk-margin-remove'>¿Por que elegir</p>
                        <p className='uk-width-1-1 uk-align-center fc-gray-text uk-h2 uk-text-uppercase uk-margin-remove michroma-font'>forcogsa?</p>
                    </div>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center">
                    <div>
                        <ul className='uk-subnav uk-subnav-pill fc-subnav-pill' data-uk-switcher="connect: .switcher-container">
                            <li className='uk-active'><a href="#"><img src={disenio} alt='disenio'/></a></li>
                            <li className='uk-margin-left'><a href="#"><img src={instalaciones}  alt='instalaciones'/></a></li>
                            <li className='uk-margin-left'><a href="#"><img src={asesoria}  alt='asesoria'/></a></li>
                            <li className='uk-margin-left'><a href="#"><img src={filosofia}  alt='filosofia'/></a></li>
                        </ul>
                    </div>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center uk-margin-top">
                    <div className='uk-width-large uk-height-small'>
                        <ul className='uk-switcher switcher-container uk-margin'>
                            <li>En nuestro departamento de Diseño, contamos con equipo y programas más avanzados. Lo que nos permite generar placas digitales para offset y grabados para flexografía con una resolución de 2540 DP.</li>
                            <li>Técnicos altamente capacitados y experimentados con más de 5,000 metros cuadrados de modernas y amplias instalaciones.</li>
                            <li>Un experimentado equipo de representantes y un adecuado soporte técnico facilitando la innovación, el diseño y la adaptación a proyectos especiales, logrando con esto cumplir con sus expectativas.</li>
                            <li>Nuestra filosofía es mantenernos a la vanguardia para ofertar y garantizar lo mejor en calidad, precio y eficiencia de nuestros productos.</li>
                        </ul>
                    </div>
                </div>
                <div className="uk-flex uk-flex-center uk-text-center uk-margin-top">
                    <div className='uk-container'>
                        <p className='uk-width-1-1 uk-align-center fc-gray-text uk-h3 uk-text-uppercase uk-margin-remove'>ALGUNOS DE NUESTROS CLIENTES</p>
                        <div className="uk-child-width-1-1 uk-grid-small uk-text-center uk-margin-top uk-margin-large-bottom" data-uk-grid>
                            <img src={marcas} />
                        </div>
                    </div>
                </div>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-text-center" data-uk-grid>
                    <div>
                        <div className="uk-section-default">
                            <div className="uk-section uk-background-cover" style={{ backgroundImage: `url(${mision})`, backgroundPosition: 'center' }}>
                                <div className="uk-container uk-text-center">
                                    <img src={mision_icon} alt='mision_icon' className='uk-margin-remove' />
                                    <h3 className='fc-green-text uk-margin-small'>MISI&Oacute;N</h3>
                                    <div className="uk-grid-match uk-child-width-1-1@s uk-height-small uk-margin-remove uk-flex-middle" data-uk-grid>
                                        <div>
                                            <p>Somos una empresa dedicada a la fabricación de materiales impresos en forma continua y prensa plana, cuidamos los más altos estándares de calidad con un servicio y asesoría personalizada a nuestros clientes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uk-section-default">
                            <div className="uk-section uk-background-cover" style={{ backgroundImage: `url(${vision})`, backgroundPosition: 'center' }}>
                                <div className="uk-container uk-text-center">
                                    <img src={vision_icon} alt='vision_icon' className='uk-margin-remove' />
                                    <h3 className='fc-green-text uk-margin-small'>VISI&Oacute;N</h3>
                                    <div className="uk-grid-match uk-child-width-1-1@s uk-height-small uk-margin-remove uk-flex-middle" data-uk-grid>
                                        <div>
                                            <p>Liderazgo en servicios de impresión en continuo crecimiento e innovación de soluciones, con presencia a nivel nacional, distinguidos por brindar productos y servicios de excelencia a nuestros clientes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uk-section-default">
                            <div className="uk-section uk-background-cover" style={{ backgroundImage: `url(${valores})`, backgroundPosition: 'center' }}>
                                <div className="uk-container uk-text-center">
                                    <img src={valores_icon} alt='valores_icon' className='uk-margin-remove' />
                                    <h3 className='fc-green-text uk-margin-small'>VALORES</h3>
                                    <div className="uk-grid-match uk-child-width-1-1@s uk-height-small uk-margin-remove uk-flex-middle" data-uk-grid>
                                        <div>
                                            <ul className='uk-list uk-list-collapse uk-margin-remove'>
                                                <li>Honestidad - Integridad</li>
                                                <li>Creatividad - Originalidad</li>
                                                <li>Colaboración - Responsabilidad</li>
                                                <li>Puntualidad - Calidad</li>
                                                <li>Seguridad</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-grid-collapse uk-child-width-expand@s uk-text-center" data-uk-grid>
                    <div className="uk-position-relative uk-visible-toggle uk-light slideshow-height" tabIndex="-1" data-uk-slideshow="animation: push; autoplay: true; finite: true; autoplay-interval: 1000">
                        <ul className="uk-slideshow-items ">
                            <li><img src={slide_11}/></li>
                            <li><img src={slide_1} alt="" /></li>
                            <li><img src={slide_2} alt="" /></li>
                            <li><img src={slide_3} alt="" /></li>
                            <li><img src={slide_4} alt="" /></li>
                            <li><img src={slide_5} alt="" /></li>
                            <li><img src={slide_6} alt="" /></li>
                            <li><img src={slide_7} alt="" /></li>
                            <li><img src={slide_8} alt="" /></li>
                            <li><img src={slide_9} alt="" /></li>
                            <li><img src={slide_10} alt="" /></li>
                        </ul>
                        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
                        <div className="uk-position-bottom-center uk-position-small uk-margin-bottom">
                            <ul className="uk-thumbnav">
                                <li uk-slideshow-item="0"><a href="#"><img src={slide_11} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="1"><a href="#"><img src={slide_1} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="2"><a href="#"><img src={slide_2} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="3"><a href="#"><img src={slide_3} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="4"><a href="#"><img src={slide_4} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="5"><a href="#"><img src={slide_5} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="6"><a href="#"><img src={slide_6} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="7"><a href="#"><img src={slide_7} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="8"><a href="#"><img src={slide_8} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="9"><a href="#"><img src={slide_9} className='thumb-size' alt="" /></a></li>
                                <li uk-slideshow-item="10"><a href="#"><img src={slide_10} className='thumb-size' alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}




export default Servicios;