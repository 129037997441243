import './App.css';
import home from './images/backgrounds/dot1.png'
import nosotros from './images/backgrounds/dot2.png'
import servicios from './images/backgrounds/dot3.png'
import productos from './images/backgrounds/dot4.png'
import portafolio from './images/backgrounds/dot5.png'
import contacto from './images/backgrounds/dot6.png'
import ubicacion from './images/backgrounds/dot7.png'

const Navigation = () => (
    <div className="navigation-bar uk-visible@s">
        <ul className="uk-iconnav uk-iconnav-vertical uk-light">
            <li className="uk-margin-remove" uk-tooltip="title: Inicio; pos: right; offset: 20"><a href="#" data-uk-scroll><img src={home} /></a></li>
            <li className="uk-margin-remove" uk-tooltip="title: Nosotros; pos: right; offset: 20"><a href="#nosotros" data-uk-scroll><img src={nosotros} /></a></li>
            <li className="uk-margin-remove" uk-tooltip="title: Servicios; pos: right; offset: 20"><a href="#servicios" data-uk-scroll><img src={servicios} /></a></li>
            <li className="uk-margin-remove" uk-tooltip="title: Productos; pos: right; offset: 20"><a href="#productos" data-uk-scroll><img src={productos} /></a></li>
            <li className="uk-margin-remove" uk-tooltip="title: Portafolio; pos: right; offset: 20"><a href="#portafolio" data-uk-scroll><img src={portafolio} /></a></li>
            <li className="uk-margin-remove" uk-tooltip="title: Contacto; pos: right; offset: 20"><a href="#contacto" data-uk-scroll><img src={contacto} /></a></li>
        </ul>
    </div>
);

export default Navigation;