import '../App.css'
/* PRODUCTOS IMAGES */
import prod_ticket from '../images/productos/ticket_productos_forcogsa.png'
import prod_rollos from '../images/productos/rollos_productos_forcogsa.png'
import prod_prensa from '../images/productos/prensaplana_productos_forcogsa.png'
import prod_codigo from '../images/productos/codigo_productos_forcogsa.png'
import prod_formacont from '../images/productos/formascontinuas_productos_forcogsa.png'
import prod_seguridad from '../images/productos/seguridad_productos_forcogsa.png'
import prod_digital from '../images/productos/digital_productos_forcogsa.png'
const productos = [
    { image: prod_ticket, title: 'Tickets para eventos', desc: 'Contamos con la tecnología para la impresión de Tickets de Acceso. Desde partidos de fútbol hasta conciertos, las medidas de seguridad y alta calidad que incorporamos en los tickets son una garantía de confianza, ya que estas permiten validar su legitimidad en el momento del evento o después si fuera necesario.' },
    { image: prod_rollos, title: 'Rollos para punto de venta', desc: 'Ideales para cajeros automáticos, cajas registradoras, sumadoras, etc. Los rollos pueden ser con o sin impresión al reverso, ya sea para promociones o datos fiscales, así se maximiza el espacio y funcionalidad. Contamos con Rollos de Papel Impresos, en Papel Bond, Papel Térmico, Papel Sensitivo, Rollos de Ticket y Rollos Auto Copiantes.' },
    { image: prod_prensa, title: 'Prensa plana', desc: 'Desarrollamos tus formatos comerciales sabiendo que no solo son una pieza de papel, si no que son herramientas que te ayuda a cumplir tus objetivos de posicionamiento o venta. Presenta tu marca por medio de Catálogos, Revistas, Folletos, Volantes, Trípticos, Folders personalizados, etc.' },
    { image: prod_codigo, title: 'Código de barras', desc: 'Este formato es de mucha utilidad como medio de identificación, puede imprimirse en un sustrato con y sin adhesivo, en sus diferentes tipos: etiquetas de productos, boletos, formularios y aplicaciones de seguridad. Generamos códigos de barras en todos los formatos, entre ellos UPC, EAN, Code 128 y sus variantes.' },
    { image: prod_formacont, title: 'Formas continuas', desc: 'Somos especialistas en formas continuas, nuestra experiencia nos permite ofrecer un servicio personalizado y de la mejor calidad. Producimos formatos de todo tipo para tu empresa, administrativos y operativos como: Guías de Embarque, Estados de Cuenta, Recibos de nómina, Boletos térmicos, Vales de Gasolina, etc.' },
    { image: prod_seguridad, title: 'Papel de seguridad', desc: 'Hemos desarrollado una amplia variedad de candados con las mejores medidas de seguridad en donde encontrarás: Papel de seguridad con filamentos ópticos, Tinta invisible, Tinta reactiva al metal, Microimpresión y Hologramas personalizados. Esto te permitirá sentirte seguro con cualquier documento o producto que requieras autenticar.' },
    { image: prod_digital, title: 'Impresión digital', desc: 'Si lo que deseas es un producto de excelente calidad de impresión en volúmenes bajos, esta es la solución. Contamos con equipo y personal capacitado para llevar a cabo las impresiones que necesites de forma rápida y sencilla.' }
]

function Productos() {
    return (
        <div className='uk-background-default' id='productos'>
            <div className="uk-flex uk-flex-center uk-margin-remove">
                <div className="uk-card uk-card-body uk-text-center uk-margin-remove">
                    <p className='uk-width-1-1 uk-align-center fc-green-text uk-h3 uk-text-uppercase uk-margin-remove'>SOLICITA ALGUNO DE NUESTROS</p>
                    <p className='uk-width-1-1 uk-align-center fc-gray-text uk-h1 uk-text-bold uk-text-uppercase uk-margin-remove'>PRODUCTOS</p>
                </div>
            </div>
            <div className='productos-background uk-padding fc-white-text'>
                <div className='uk-container uk-text-center'>
                    <div data-uk-grid>
                        <div className="uk-width-auto@m uk-margin-medium-top">
                            <ul className="uk-tab-left" data-uk-tab="connect: #component-tab-left; animation: uk-animation-fade">
                                <li><a href="#">Tickets para eventos</a></li>
                                <li><a href="#">Rollos para punto de venta</a></li>
                                <li><a href="#">Prensa plana</a></li>
                                <li><a href="#">C&oacute;digo de barras</a></li>
                                <li><a href="#">Formas continuas</a></li>
                                <li><a href="#">Papel de seguridad</a></li>
                                <li><a href="#">Impresi&oacute;n digital</a></li>
                            </ul>
                        </div>
                        <div className="uk-width-expand@m uk-height-medium">
                            <ul id="component-tab-left" className="uk-switcher">
                                <ProdList list={productos} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}







function ProdList(props) {
    const items = props.list.map((item, i) =>
        <li key={i}>
            <div className="uk-card uk-grid-collapse uk-child-width-1-2@s uk-margin" data-uk-grid>
                <div className="uk-card-media-left uk-cover-container">
                    <img src={item.image} alt="" />
                </div>
                <div>
                    <div className="uk-card-body uk-text-left">
                        <h3 className="uk-card-title fc-white-text">{item.title}</h3>
                        <p>{item.desc}</p>
                        <button className='uk-button fc-button'>Solicita este producto</button>
                    </div>
                </div>
            </div>
        </li>
    )
    return items
}


export default Productos;