import '../App.css'
import grupoforcogsa from '../images/grupo-forcogsa-logo.jpg'
import forcogsa from '../images/forcogsa-logo-grupo.jpg'
import formflex from '../images/formflex-logo.jpg'
import formas from '../images/formas-digitales-logo.jpg'

function Contacto() {
    return (
        <>
            <div className='uk-container-expand uk-background-default uk-padding' id='contacto'>
                <div className='uk-container'>
                    <div className="uk-child-width-expand@s" data-uk-grid>
                        <div className="uk-grid-item-match uk-text-center">
                            <div className="uk-card uk-card-body uk-margin-top">
                                <h3 className='fc-green-text uk-text-bold'>Para m&aacute;s informaci&oacute;n</h3>
                                <p className='uk-margin-remove'>ventasfcg@forcogsa.com.mx</p>
                                <p className='uk-margin-remove fc-yellow-text uk-text-bold'>CONMUTADOR</p>
                                <p className='uk-margin-remove'>01 (33) 3777-1616</p>
                                <br />
                                <p className='uk-margin-remove'>Anillo Periférico Pte. #7301</p>
                                <p className='uk-margin-remove'>Vallarta Parque Industrial</p>
                                <p className='uk-margin-remove'>C.P. 45010</p>
                                <p className='uk-margin-remove'>Zapopan, Jal. México</p>
                            </div>
                        </div>
                        <div className="uk-text-center">
                            <div className="uk-card uk-card-body">
                                <img src={grupoforcogsa} />
                                <div className='uk-margin'><img src={forcogsa} /></div>
                                <div className='uk-margin'><img src={formflex} /></div>
                                <div className='uk-margin'><img src={formas} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='uk-container-expand'>
                <iframe title="web" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7465.357289841088!2d-103.455818!3d20.682649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x53d471a9846c5219!2sFormas%20Continuas%20de%20Guadalajara%20S.A.%20de%20C.V.!5e0!3m2!1ses-419!2smx!4v1654018888464!5m2!1ses-419!2smx" width="100%" height="450" frameBorder="0" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    );
}


export default Contacto;