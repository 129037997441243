import '../App.css'
import trebol_white from '../images/icono-forcogsa.png'
import impresion from '../images/imagen_liderimpresion.jpg'

function Nosotros() {
    return (
        <>
            {/* VIDEO FORCOGSA MODAL */}
            <div className='uk-container-expand'>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-height-large uk-light">
                    <div className="uk-card uk-card-body uk-text-center">
                        <img src={trebol_white} alt='trebol' width='85' className='uk-responsive' />
                        <h1 className='uk-heading-small'>Soluciones en Impresi&oacute;n</h1>
                        <div className='playBtn' data-uk-toggle="target: #modal-media-youtube"><span uk-icon="icon: play; ratio: 2" className='uk-margin-small-top'></span></div>
                        <p className='uk-width-1-2 uk-align-center fc-white-text uk-text-default'>En Forcogsa tenemos la solución para tus necesidades de impresión, desde papelería en forma continua hasta boletos de alta seguridad para espectáculos.</p>
                    </div>
                </div>
            </div>
            {/* GREEN SECTION */}
            <div className='uk-container-expand green-separator-up'></div>
            <div className='uk-container-expand fc-green-background'>
                <div className='uk-container' id='nosotros'>
                    <div className="uk-flex uk-flex-middle uk-flex-center uk-light" data-uk-grid>
                        <div className="uk-width-1-2@l uk-flex-first">
                            <img src={impresion} alt="impresion" />
                        </div>
                        <div className="uk-width-1-2@l">
                            <h3 className="uk-text-uppercase fc-yellow-text">L&iacute;DER EN IMPRESI&oacute;N A</h3>
                            <h1 className="uk-text-uppercase fc-white-text uk-margin-remove uk-text-bold">Nivel Nacional</h1>
                            <p className='fc-white-text'>En 1969 nace Forcogsa como una empresa orgullosamente mexicana. A partir de entonces hemos perfeccionado nuestros procesos de producci&oacute;n manteni&eacute;ndonos como n&uacute;mero uno en la elaboraci&oacute;n de formatos pre impresos en forma continua, documentos con medidas de seguridad, boletaje para espect&aacute;culos, prensa plana y rollos impresos.</p>
                            <p className='fc-white-text'>Esto, gracias a la tecnolog&iacute;a de punta aplicada y la alta capacidad de producci&oacute;n instalada.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='uk-container-expand green-separator-down uk-background-default'></div>
            {/* VIDEO FORCOGSA MODAL */}
            <div id="modal-media-youtube" className="uk-flex-top" data-uk-modal>
                <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                    <button className="uk-modal-close-outside" type="button" data-uk-close></button>
                    <iframe src="https://www.youtube.com/embed/vXCp46kAjFM" width="1200" height="675" data-uk-video="autoplay: inview" data-uk-responsive></iframe>
                </div>
            </div>
        </>
    );
}

export default Nosotros;